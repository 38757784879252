import { useCustomForm } from "common/hooks/src/api/useCustomForm";
import { Button } from "components/button";
import { CardWithTitle } from "components/card/CardWithTitle";
import InputField from "components/fields/InputField";
import { useCreateArticle } from "modules/article/api";
import {
  CreateArticlePayload
} from "modules/article/types/article.interface";
import { FormEvent } from "react";
import "react-datepicker/dist/react-datepicker.css";

const CreateArticle = () => {
  const { mutate } = useCreateArticle();
  const { formValues, setFormValues } = useCustomForm<CreateArticlePayload>({
    label: "",
    priceHT: 0,
    priceTTC: 0,
    type: "",
  });

  const isSubmitDisabled =
    !formValues.label ||
    !formValues.type ||
    !formValues.priceHT ||
    !formValues.priceTTC;

  const handleSubmit = (e: FormEvent) => {
    if (isSubmitDisabled) return;

    e.preventDefault();

    mutate({
      ...formValues,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <CardWithTitle title="Information du article">
        <div className="grid grid-cols-2 gap-5">
          <InputField
            variant="auth"
            extra="mb-3"
            label="Label*"
            placeholder="Manque Fluide"
            id="label"
            type="text"
            value={formValues.label}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                label: e.target.value,
              })
            }
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Type*"
            placeholder="Service"
            id="type"
            type="text"
            value={formValues.type}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                type: e.target.value,
              })
            }
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Prix HT*"
            placeholder="100"
            id="type"
            type="number"
            value={formValues.priceHT}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                priceHT: parseFloat(e.target.value),
              })
            }
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Prix TTC*"
            placeholder="120"
            id="type"
            type="number"
            value={formValues.priceTTC}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                priceTTC: parseFloat(e.target.value),
              })
            }
          />
        </div>
      </CardWithTitle>
      <div className="mt-2 flex w-full justify-end">
        <div className="w-full max-w-xs">
          <Button onClick={handleSubmit} disabled={isSubmitDisabled}>
            Ajouter l'article
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CreateArticle;
