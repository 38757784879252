import { useAppSelector } from "common/hooks";
import { useGetSubscriptionStatus } from "modules/workspaces/api";
import { FunctionComponent, PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";

export const LayoutController: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const user = useAppSelector((state) => state.user.user);
  const auth = user  !== null;
  const { data, isLoading } = useGetSubscriptionStatus();
  const location = useLocation();

  if (auth && user?.role !== "admin") {
    return (
      <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 text-center">
        <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
            Vous pouvez maintenant accéder à l'application
          </h4>
        </div>
      </div>
    );
  }

  if (
    !isLoading &&
    !data?.data.active &&
    auth &&
    !location.pathname.includes("/auth/subscription")
  ) {
    return <Navigate to="/auth/subscription" replace={true} />;
  }

  if (
    !isLoading &&
    data?.data.active &&
    auth &&
    location.pathname.startsWith("/auth")
  ) {
    return <Navigate to="/" replace={true} />;
  }

  if (!auth && !location.pathname.startsWith("/auth")) {
    return <Navigate to="/auth/sign-in" replace={true} />;
  }

  return <>{children}</>;
};
