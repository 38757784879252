import { Button } from "components/button";
import InputField from "components/fields/InputField";
import { useGetUserState, useSignIn } from "modules/auth/api";
import { WorkspaceUserState } from "modules/auth/types/auth.interface";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function SignIn() {
  const { mutate } = useSignIn();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { mutate: mutateUserState, data: userState } = useGetUserState();
  const navigate = useNavigate();

  const disabled =
    !email || (userState?.data === WorkspaceUserState.PasswordSet && !password);

  const isPasswordSet = useMemo(
    () => userState?.data === WorkspaceUserState.PasswordSet,
    [userState]
  );

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if (userState?.data && userState?.data === WorkspaceUserState.PasswordSet) {
        mutate({ email, password });
      } else {
        mutateUserState(email);
      }
    },
    [email, password, userState, mutate, mutateUserState]
  );

  useEffect(() => {
    if (userState?.data === WorkspaceUserState.PasswordNotSet) {
      navigate("/auth/set-password?email=" + email);
    }
  }, [email, navigate, userState]);

  return (
    <form
      className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2"
      onSubmit={handleSubmit}
    >
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Connexion
        </h4>
        <p className="mb-6 ml-1 text-base text-gray-600">
          Entrer votre email et mot de passe pour vous connecter!
        </p>
        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@domain.com"
          id="email"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={userState?.data !== undefined}
        />

        {isPasswordSet && (
          <InputField
            variant="auth"
            extra="mb-3"
            label="Mots de passe*"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        )}

        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href="/auth/forgot-password"
          >
            Mots de passe oublié?
          </a>
        </div>
        <Button onClick={handleSubmit} disabled={disabled}>
          {isPasswordSet ? "Connexion" : "Continuer"}
        </Button>
      </div>
    </form>
  );
}
