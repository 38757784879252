import { SuccessResponse } from "common/common.interface";
import { environment } from "common/environment";
import {
  AddStaffPayload,
  PaymentHistory,
  WorkspaceStaff
} from "modules/workspaces/types/workspaces.interface";

export interface IWorkspaceBackend {
  getWorkspaceStaff(): Promise<WorkspaceStaff[]>;
  getWorkspaceSubHistory(): Promise<PaymentHistory>;
  addToStaff(payload: AddStaffPayload): Promise<WorkspaceStaff>;
  removeFromStaff(userId: string): Promise<SuccessResponse>;
  getSubscriptionStatus(): Promise<SuccessResponse<{ active: true }>>;
  getSubscriptionLink(): Promise<SuccessResponse<{ link: string }>>;
}

let workspaceBackendInstance: IWorkspaceBackend | undefined;

export async function getWorkspaceBackend(): Promise<IWorkspaceBackend> {
  if (workspaceBackendInstance === undefined) {
    const mod = await import(`./${environment.WORKSPACE_BACKEND}`);
    workspaceBackendInstance = new mod.default() as IWorkspaceBackend;
  }
  return workspaceBackendInstance;
}
