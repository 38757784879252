import { SuccessResponse } from "common/common.interface";
import { useAppSelector, useSnack } from "common/hooks";
import { useMutationWithLoading } from "common/hooks/src/api/useMutationWithLoading";
import { useQueryWithLoading } from "common/hooks/src/api/useQueryWithLoading";
import { WorkspaceUserRole } from "modules/auth/types/auth.interface";
import {
  AddStaffPayload,
  PaymentHistory,
  WorkspaceStaff,
} from "modules/workspaces/types/workspaces.interface";
import { UseMutationResult, UseQueryResult, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { getWorkspaceBackend } from "./backends";
import { CABINETS_ROUTES } from "./workspaces.api.enum";

export const useGetWorkspaceStaff = (): UseQueryResult<WorkspaceStaff[]> => {
  const setSnack = useSnack();

  const getWorkspaceStaff = async (): Promise<WorkspaceStaff[]> => {
    const workspaceBackend = await getWorkspaceBackend();
    return await workspaceBackend.getWorkspaceStaff();
  };

  return useQueryWithLoading(
    CABINETS_ROUTES.GET_CABINET_STAFF,
    () => getWorkspaceStaff(),
    {
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useGetWorkspaceSubHistory = (): UseQueryResult<PaymentHistory> => {
  const setSnack = useSnack();

  const getWorkspaceSubHistory = async (): Promise<PaymentHistory> => {
    const workspaceBackend = await getWorkspaceBackend();
    return await workspaceBackend.getWorkspaceSubHistory();
  };

  return useQueryWithLoading(
    CABINETS_ROUTES.GET_CABINET_SUB_HISTORY,
    () => getWorkspaceSubHistory(),
    {
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useGetSubscriptionStatus = (): UseQueryResult<
  SuccessResponse<{
    active: true;
  }>
> => {
  const setSnack = useSnack();
  const user = useAppSelector((state) => state.user.user);

  const getSubscriptionStatus = async (): Promise<
    SuccessResponse<{ active: true }>
  > => {
    const workspaceBackend = await getWorkspaceBackend();
    return await workspaceBackend.getSubscriptionStatus();
  };

  return useQueryWithLoading(
    CABINETS_ROUTES.GET_SUBSCRIPTION_STATUS,
    () => getSubscriptionStatus(),
    {
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
      enabled: user !== null,
    }
  );
};

export const useAddToStaff = (): UseMutationResult<
  WorkspaceStaff,
  Error,
  AddStaffPayload
> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const addToStaff = async (
    payload: AddStaffPayload
  ): Promise<WorkspaceStaff> => {
    const workspaceBackend = await getWorkspaceBackend();
    return await workspaceBackend.addToStaff(payload);
  };

  return useMutationWithLoading(
    (payload: AddStaffPayload) => addToStaff(payload),
    {
      onSuccess() {
        setSnack({
          title: "Membre du staff ajouter avec succès!",
          severityType: "success",
        });

        queryClient.refetchQueries(CABINETS_ROUTES.GET_CABINET_STAFF);

        navigate("/admin/staff");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useRemoveFromStaff = (): UseMutationResult<
  SuccessResponse,
  Error,
  string
> => {
  const setSnack = useSnack();
  const queryClient = useQueryClient();

  const removeStaff = async (userId: string): Promise<SuccessResponse> => {
    const workspaceBackend = await getWorkspaceBackend();
    return await workspaceBackend.removeFromStaff(userId);
  };

  return useMutationWithLoading((userId: string) => removeStaff(userId), {
    onSuccess() {
      setSnack({
        title: "Membre du staff supprimer avec succès!",
        severityType: "success",
      });

      queryClient.refetchQueries(CABINETS_ROUTES.GET_CABINET_STAFF);
    },
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
  });
};

export const useGetSubscriptionLink = (): UseQueryResult<
  SuccessResponse<{
    link: string;
  }>
> => {
  const setSnack = useSnack();
  const user = useAppSelector((state) => state.user.user);
  const queryClient = useQueryClient();
  const subStatus = queryClient.getQueryData(
    CABINETS_ROUTES.GET_SUBSCRIPTION_STATUS
  ) as SuccessResponse<{ active: boolean }> | undefined;

  const getSubscriptionLink = async (): Promise<
    SuccessResponse<{
      link: string;
    }>
  > => {
    const workspaceBackend = await getWorkspaceBackend();
    return await workspaceBackend.getSubscriptionLink();
  };

  return useQueryWithLoading(
    CABINETS_ROUTES.GET_SUBSCRIPTION_LINK,
    () => getSubscriptionLink(),
    {
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
      enabled:
        user !== null &&
        user.role === WorkspaceUserRole.Admin &&
        subStatus?.data.active === false,
    }
  );
};
