import { useGetSubscriptionLink } from "modules/workspaces/api";
import { useEffect } from "react";

export default function Subscription() {
  const { data } = useGetSubscriptionLink();

  useEffect(() => {
    if (data) {
      window.location.replace(data.data.link);
    }
  }, [data]);

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Abonnement inactif
        </h4>
        <p className="mb-6 ml-1 text-base text-gray-600">
          Contacter l'administrateur pour activer l'abonnement
        </p>
      </div>
    </div>
  );
}
