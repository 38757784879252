import { useAppSelector } from "common/hooks";
import { Button } from "components/button";
import { CardWithTitle } from "components/card/CardWithTitle";
import InputField from "components/fields/InputField";
import { useAddToStaff } from "modules/workspaces/api";
import { FormEvent, useState } from "react";

const AddToStaff = () => {
  const { mutate } = useAddToStaff();
  const [nomDuGerant, setNomDuMembre] = useState("");
  const [emailDuGerant, setEmailDuMembre] = useState("");
  const isSubmitDisabled = !nomDuGerant || !emailDuGerant;
  const user = useAppSelector((state) => state.user.user);
  const workspaceId  = user?.workspaceId;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    mutate({
      name: nomDuGerant,
      email: emailDuGerant,
      workspaceId: workspaceId,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <CardWithTitle title="Informations du membre">
        <div className="grid grid-cols-2 gap-5">
          <InputField
            variant="auth"
            extra="mb-3"
            label="Nom*"
            placeholder="Paul Dupont"
            id="nom-du-member"
            type="text"
            value={nomDuGerant}
            onChange={(e) => setNomDuMembre(e.target.value)}
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="paul.dupont@mail.com"
            id="email-du-member"
            type="text"
            value={emailDuGerant}
            onChange={(e) => setEmailDuMembre(e.target.value)}
          />
        </div>
      </CardWithTitle>
      <div className="mt-2 flex w-full justify-end">
        <div className="w-full max-w-xs">
          <Button onClick={handleSubmit} disabled={isSubmitDisabled}>
            Ajouter au staff
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AddToStaff;
