import { useGetWorkspaceStaff } from "modules/workspaces/api";
import { WorkspaceStaff } from "modules/workspaces/types/workspaces.interface";
import { useCallback } from "react";
import ComplexTable, { RowStaffObj } from "../default/components/ComplexTable";

const Staff = () => {
  const { data } = useGetWorkspaceStaff();

  const handleFormatData = useCallback(
    (data: WorkspaceStaff[]): RowStaffObj[] => {
      if (!data) return [];

      return data.map((item) => ({
        id: item.id,
        name: item.name,
        email: item.email,
        role: item.role,
      }));
    },
    []
  );

  return (
    <div>
      <div className="mt-5">
        {data && data.length > 0 && (
          <ComplexTable tableData={handleFormatData(data)} />
        )}
      </div>
    </div>
  );
};

export default Staff;
