import { useGetWorkspaceSubHistory } from "modules/workspaces/api";
import { formatStripePrice } from "modules/workspaces/api/src/utils/formatStripePrice";
import { PaymentHistory } from "modules/workspaces/types/workspaces.interface";
import SubscriptionTable, {
    SubHistoryObj,
} from "views/admin/default/components/SubscriptionTable";

const SubscriptionHistory = () => {
  const { data } = useGetWorkspaceSubHistory();

  const handleFormatData = (data: PaymentHistory): SubHistoryObj[] => {
    const payments = data.payments.map((item) => ({
      date: new Date(item.date).toLocaleDateString(),
      id: item.paymentIntentId,
      status: {
        text: "Succès",
        type: "approved",
      },
      totalAmount: formatStripePrice(item.totalAmount),
    }));

    const failedPayments = data?.failedPayment
      ? {
          date: new Date(data.failedPayment.date).toLocaleDateString(),
          id: "",
          status: {
            text: "Paiement échoué",
            type: "error",
          },
          totalAmount: formatStripePrice(data.failedPayment.totalAmount),
        }
      : undefined;

    return [failedPayments, ...payments].filter(Boolean) as SubHistoryObj[];
  };

  return (
    <div>
      <div className="mt-5">
        {data && data.payments.length > 0 && (
          <SubscriptionTable tableData={handleFormatData(data)} />
        )}
      </div>
    </div>
  );
};

export default SubscriptionHistory;
